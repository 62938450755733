html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Roboto', serif;
  background-color: var(--background);
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.page-content-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;

}
.page_content_body{
  padding: 0.5em;
  width: calc(100% - 3.6em);
}
.nav__button{
  font-size: 80%;
  padding: 0.3em 0.5em;
  border-radius: 0.8em;
  border: 0;
  cursor: pointer;
  background-color: var(--grey0);
  &:active{ transform: scale(0.98) }
}
.contentWindow{
  border-radius: 0.2em;
  background-color: var(--containers-back);
  overflow: hidden;
  border: 0.01em solid var(--grey0);
}
.contentWindow__header{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3em;
  color: var(--font-color);
  background-color: var(--grey0);
  font-size: 90%;
}
.contentWindow__name{  }
.contentWindow__nav{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--grey2);
  padding: 0.3em 0;
  height: 1.5em;
}
.contentWindow__body{ height: 50vh }

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 0.1em var(--grey0);
}

::-webkit-scrollbar-thumb {
  background: var(--grey2);
  border-radius: 0.2em;
  box-shadow: inset 0 0 0.2em var(--grey0);
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input {
  outline: none;
}
select {
  outline: none;
}
button {
  outline: none;
}

.loader {
  width: 1.7em;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
          linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
          linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}



@media (min-width: 240px) {
  .navIndent { width: 3.1em }
  .navPanel { width: 2.5em }

  .auth__mmrzMe-container { font-size: 110%; }
  body { font-size: 10px }
  .contentWindow__body { height: 33vh }
  .main { overflow-y: auto }
  .main__task-deal-history-container { display: block; }
  .main__data-container { width: calc(100% - 0.2em); margin-bottom: 0.5em; height: auto }
  .main__task-select { width: 8em }
  .companies__search-input { margin-bottom: 0.5em }
  .companies__select { margin: 0 0.5em 0 0 }
  .companies__popup-body { font-size: 120%; }
  .companies__popup-input{ width: 30vw }
  .companies__body { overflow: auto }
  .companies__data-container { overflow-x: auto }

  .selectedComp { overflow: hidden; overflow-y: auto }
  .selectedComp_body{ padding: 0.5em 0 0.5em 0.5em; }
  .selectedComp_company-data-container, .selectedComp_reports-container { flex-direction: column }
  .selectedComp__comp-container { width: calc(100% - 0.2em); margin: 0 0 0.5em 0 }
  .selectedComp__rpr-container { width: calc(100% - 0.2em); margin: 0 }
  .selectedComp__data-body { min-height: 15vh }
  .selectedComp__rpr-input { font-size: 100%; }
  .selectedComp__input, .selectedComp__select { font-size: 100%; }
  .selectedComp__rpr-edit-tools-container { left: -0.5em }
  .selectedComp__rpr-edit-tools-btn { width: 1.9em; height: 2em }
  .selectedComp_calculation-cotainer { overflow-x: auto; min-height: 30vh }
  .selectedComp__calc-container { width: 170% }
  #PopupTable { width: 170% }
  .selectedComp__calcs-container { width: calc(100% - 0.2em); margin: 0.5em 0 0.5em 0 }
  .selectedComp__history-container { width: calc(100% - 0.2em); margin: 0 }
  .selectedSupplier__history-container { width: calc(100% - 0.2em); margin: 0 }
  .selectedComp__calc-btn-openPopup { width: 1.2em; height: 1.1em }

  .selectedComp__calc-popup-body-wrapper { width: 95vw; height: 50vh }
  .calcPopup__calculation-container { overflow-x: auto }
  .calcPopup__toolsBtn { font-size: 100%; }
  .calcPopup__toolsBtn-unusable { font-size: 100%; }

  .profile__content-container { width: calc(100% - 0.2em) }
  .profile__table input { font-size: 100%; }

  .admin__body { width: auto }
  .admin__user-button { font-size: 110%; }
  .admin__user-table-container input { font-size: 100%; }
  .admin__user-permissions-window-header { height: 8% }
  .admin__user-permissions-window-body { height: 92% }
  .admin__newUser-form { width: 80% }
  .admin__data-body-user-info { overflow-y: auto; height: 40vh }
  .admin__user-permissions-container { flex-direction: column; align-items: center }
  .admin__permissions-selected { width: 80%; margin: 0 }
  .admin__permissions-available { width: 80%; margin: 0.5em 0 0 0 }
  .admin__from-btn { padding: initial }
  .admin__pdf-manager-settings-table { width: 100% }


}
@media (min-width: 767px) {
  body { font-size: 12px }
  .contentWindow__body { height: 50vh }
  .companies__search-input { margin-bottom: 0 }
  .companies__select { margin-left: 0.5em }
  .selectedComp__data-body { min-height: 40vh }
  .selectedComp__calc-popup-body-wrapper { width: 95vw; height: 80vh }

  .admin__user-permissions-container { flex-direction: row; align-items: initial }
  .admin__permissions-selected { width: 50%; margin: 0; }
  .admin__permissions-available { width: 50%; margin: 0 0 0 0.5em; }


}
@media (min-width: 1279px) {
  body { font-size: 14px }
  .contentWindow__body { height: 50vh }
  .main__task-deal-history-container { display: flex; justify-content: space-between }
  .main__data-container { width: 33% }
  .companies__popup-input{ width: 15em; font-size: 100%; }
  .selectedComp_company-data-container, .selectedComp_reports-container { flex-direction: row }
  .selectedComp__comp-container { width: calc(50% - 0.2em); margin: 0 0.5em 0 0 }
  .selectedComp__rpr-container { width: calc(50% - 0.2em) }
  .selectedComp__data-body { min-height: auto }
  .selectedComp__rpr-edit-tools-container { left: 0 }
  .selectedComp__rpr-edit-tools-btn { width: 2.1em; height: 2.2em }
  .selectedComp__calc-container { width: calc(100% - 0.2em) }
  #PopupTable { width: calc(100% - 0.2em) }
  .selectedComp__calcs-container { width: calc(50% - 0.2em); margin: 0.5em 0.25em 0 0 }
  .selectedComp__history-container { width: calc(50% - 0.2em); margin: 0.5em 0 0 0.25em }
  .selectedSupplier__history-container{ width: calc(50% - 0.25em) }
  .selectedComp__calc-btn-openPopup { width: 1.4em; height: 1.3em }
  .selectedComp_calculation-cotainer { overflow-x: initial; min-height: auto }

  .profile__table { width: 60% }

  .admin__user-permissions-window-header { height: 6% }
  .admin__user-permissions-window-body { height: 94% }
  .admin__newUser-form { width: 50% }
  .admin__from-btn { padding: 0.3em 0; width: 60%; margin: 0 auto }
  .admin__pdf-manager-settings-table { width: 50% }
}
@media (min-width: 1439px) {

  .profile__table { width: 40% }

  .admin__permissions-selected { width: 40%; }
  .admin__permissions-available { width: 40%; }
}


