.companies__data-container{

}
.companies__data-nav-panel{
    padding: 0.5em;
    background-color: var(--grey0);
    border-radius: 0.5em 0.5em 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.companies__table{
    width: 100%;
    border-collapse: collapse;
}
.companies__thead{
    background-color: var(--grey1);
}
.companies__tbody{
    background-color: var(--containers-back);
}
.companies__td{ border: 0.1em solid black; padding: 0.3em }
.companies__tr{ border: 0.1em solid black }
.companies__tr-head{ color: var(--main-color); text-align: center }
.companies__tr-body{ color: var(--font-color); }
.companies__search-input{
    padding: 0.25em;
    border: 0;
    border-radius: 0.25em 0 0 0.25em;
}
.companies__search-btn{
    padding: 0.25em 0.5em;
    border: 0;
    border-radius: 0 0.25em 0.25em 0;
    background-color: var(--main-color);
    cursor: pointer;
    &:active{ transform: scale(0.98) }
    box-shadow: 0 0 0.2em var(--main-color);
}

.companies__btn{
    border-radius: 0.5em;
    background-color: var(--grey1);
    border: 0;
    padding: 0.3em;
    cursor: pointer;
    &:active{ transform: scale(0.98) }
}
.companies__btn-add-company {
    color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
    &:active, &:hover{
        color: var(--success-color);
        box-shadow: 0 0 0.2em var(--success-color);
    }
}
.companies__ref{
    color: var(--ref-color);
    &:visited{ color: var(--ref-color) };
    &:active{ color: var(--ref-color-active) };
    text-decoration: none;
}
.companies__popup-body{
    padding: 0.5em;
}
.companies__popup-table{ border-collapse: collapse }
.companies__popup-td{
    border: 0.1em solid var(--grey0);
}
.companies__popup-td-dynamic{
    background-color: var(--containers-back);
    position: relative;
}
.companies__popup-td-static{
    height: 3vh;
    color: var(--main-color);
}
.companies__popup-input{
    border: 0;
    background-color: var(--containers-back);
    width: 18vw;
    height: 100%;
    color: var(--font-color);
    &:focus {
        outline: none;
    }
}
.companies__popup-select{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    border: 0;
    background-color: var(--containers-back);
    color: var(--font-color);
    &:focus {
        outline: none;
    }
}
.companies__tools-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em 0 0 0;
}
.popup__btn-save{
        color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
    &:active, &:hover{
        color: var(--success-color);
        box-shadow: 0 0 0.2em var(--success-color);
    }
}
.popup__btn-reset, .companies__btn-reset{
        color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
    &:active, &:hover{
        color: var(--main-color);
        box-shadow: 0 0 0.2em var(--main-color);
    }
}
.companies__select{
    margin: 0 0.5em;
    padding: 0.25em;
    border: 0;
    border-radius: 0.25em;
    background-color: var(--grey1);
    color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
}

