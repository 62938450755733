.popupBack {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup__container{

    background-color: var(--background);
    border-radius: 0.5em;
    overflow: hidden;
}
.popup__header{
    padding: 0.25em;
    background-color: var(--grey0);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    color: var(--font-color);
}
.popup__header-close{
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    height: 100%;
    width: 3em;
    background-color: var(--main-color);
    cursor: pointer;
    &:active{ transform: scale(0.98) }
}
.popup__close-symbol{
    font-size: 130%;
    color: var(--grey1);
}
