:root {

    --grey0: rgb(31, 31, 31);
    --grey1:  #343434;
    --grey2: #3b3b3b;
    --background: rgb(80, 80, 80);
    --containers-back:  #6e6e6e;
    --grey-light: #8c8c8c;
    --ref-color: rgb(138, 212, 255);
    --ref-color-active: rgb(0, 18, 182);
    --font-color: #e9e9e9;
    --fc-on-press: #9e9e9e;
    --success-color: #85FF85FF;
    --main-color: #FAC768FF;
    --warning-color: #FF5858FF;
}