.main__task-deal-history-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.main__data-container{
    width: 33%;
    height: 100%;
}
.main__data-header{}
.main__data-nav{}
.main__history-button{
    color: var(--font-color);
    box-shadow: 0 0 0.25em var(--font-color);
}
.main__tasks-button{
    color: var(--font-color);
    box-shadow: 0 0 0.25em var(--font-color);
}
.main__tasks-button-create{
    &:active, &:hover {
        color: var(--success-color);
        box-shadow: 0 0 0.25em var(--success-color);
    }
}
.main__task-body{ overflow-y: auto }
.main__task-textarea-container {
    padding: 0.25em;
    width: calc(100% - 0.5em);
    display: flex;
    flex-direction: column;
}
.main__task-textarea {
    resize: none;
    border: 0;
    width: calc(100% - 0.25em);
    height: 15vh;
    border-radius: 0.5em;
    box-shadow: 0 0 0.25em black;
    background-color: #efefef;
    &:focus {
        outline: none;
    }
}
.main__task-options-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em 0.25em;
    width: calc(100% - 0.5em);
    /*margin-top: 0.5em;*/
}
.main__task-select{
    color: var(--font-color);
    font-size: 80%;
    padding: 0.2em 0.5em;
    border-radius: 0.25em;
    border: 0;
    cursor: pointer;
    background-color: var(--grey0);
    width: 8vw;
}
.main__task-select-edit {
    color: var(--font-color);
    font-size: 80%;
    padding: 0.2em 0.5em;
    border-radius: 0.25em;
    border: 0;
    cursor: pointer;
    background-color: #516265;
    width: 50%;
}
.main__task-select-wrapp-user {width: 100%;}
.main__task-select-edit-user {
    color: var(--font-color);
    font-size: 80%;
    padding: 0.2em 0.5em;
    border-radius: 0.25em;
    border: 0;
    cursor: pointer;
    background-color: #516265;
    width: 25%;
}
.main__task-btn-save{
    color: var(--font-color);
    box-shadow: 0 0 0.25em var(--font-color);
    height: 2em;
    &:active, &:hover {
        color: var(--success-color);
        box-shadow: 0 0 0.25em var(--success-color);
    }
}
.main__task-select-wrapper{ display: flex; flex-direction: column; align-items: center }
.main__task-select-wrapper span {
    margin-bottom: 0.2em;
    font-size: 70%;
    color: var(--main-color);
}
.main__task-btn-save-wrapper { display: flex; justify-content: center; align-items: center }
.task__response-msg { display: flex; justify-content: center; font-size: 90% }
.main__tasks-task-wrapper { padding: 0.25em 0.5em; }
.main__tasks-empty-task-container { display: flex; justify-content: center; color: var(--font-color) }
.main__tasks-task-container {
    border-radius: 0.25em;
    overflow: hidden;
    box-shadow: 0 0 0.1em black;
    background-color: rgba(61, 127, 139, 0.3);
}
.main__tasks-task-header {
    display: flex;
    flex-direction: row;
    padding: 0.2em;
    justify-content: space-between;
}
.main__tasks-task-body {
    padding: 0.2em;
    display: flex;
    flex-direction: row;
}
.main__task-content-container {
    word-wrap: break-word;
    white-space: normal;
    width: calc(100% - 1.7em);
}
.main__task-edit-container {
    padding-left: 0.2em;
    width: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}
.main__task-edit-btn{
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.4em;
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        transform: scale(0.90);
    }
}
.main__task-save-btn{
    width: 1.4em;
    aspect-ratio: 1 / 1;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.4em;
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        transform: scale(0.90);
    }
}
.main__task-back-btn{
    width: 1.4em;
    aspect-ratio: 1 / 1;
    padding: 0;
    margin-left: 0.4em;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.4em;
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        transform: scale(0.90);
    }
}
.main__task-edit-btn-configs{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right: 0;
    top: 0;
    border-radius: 0.5em;
    background-color: var(--grey2);
    box-shadow: 0 0 0.1em var(--font-color);
    padding: 0.3em;
}
.main__task-edit-btn-unactive{
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.4em;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main__task-edit-ico{
    width: 100%;
    aspect-ratio: 1 / 1;
}
.main__tasks-textarea{
    width: 100%;
    resize: none;
    border: 0;
    font-size: 90%;
    line-height: 1.4;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 0.5em;
    color: var(--font-color);
    outline: none;
    box-shadow: 0 0 0.1em var(--grey0);
}
.main__tasks-task-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2em;
}
.main__tasks-task-header span{
    color: var(--grey2);
}
.main__tasks-task-btn {
    padding: 0.2em 0.5em;
    border: 0;
    font-size: 85%;
    border-radius: 0.5em;
    background-color: var(--grey1);
    color: var(--font-color);
    box-shadow: 0 0 0.15em var(--font-color);
    cursor: pointer;
    &:active {
        transform: scale(0.96);
    }
}
.main__tasks-task-takeTask {
    &:active, &:hover {
        color: var(--success-color);
        box-shadow: 0 0 0.25em var(--success-color);
    }
}
.main__tasks-task-delTask {
    &:active, &:hover {
        color: var(--warning-color);
        box-shadow: 0 0 0.25em var(--warning-color);
    }
}
.main__tasks-task-cancelTask {
    &:active, &:hover {
        color: var(--main-color);
        box-shadow: 0 0 0.25em var(--main-color);
    }
}
.main__tasks-task-companyRef {
    font-size: 90%;
    margin-bottom: 0.25em;
    color: var(--ref-color);
    &:visited {
        color: var(--ref-color);
    }
}
.main__tasks-task-context-container{ padding-left: 0.5em; margin: 0.5em 0; }
.main__tasks-task-context {
    color: var(--font-color);
    font-size: 100%;
}
.main__tasks-task-doingBy-name { color: var(--success-color); font-size: 90% }
.main__history-successText { color: rgba(133, 255, 133, 0.8); text-decoration: underline }
.main__history-infoText { color: rgba(250, 199, 104, 0.8); text-decoration: underline }
.main__history-warningText { color: rgba(255, 88, 88, 0.8); text-decoration: underline }
.main__calc-data-body { overflow-y: auto }
.main__calc-table {
    border-collapse: collapse;
    width: 100%;
}
.main__calc-table td {
    border: 0.1em solid var(--grey0);
    font-size: 90%;
    padding: 0.2em;
    text-align: center;
}
.main__calc-thead {
    color: var(--main-color);
}
.main__calc-tbody {
    color: var(--font-color);
}
.main__calc-tbody a {
    color: var(--ref-color);
    &:active, &:visited {
        color: var(--ref-color);
    }
}
.main__calc-btn {
    background-color: var(--grey2);
    border: 0;
    cursor: pointer;
    border-radius: 0.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1em 0;
    margin: 0 auto;
    &:active {
        transform: scale(0.98);
    };
}
.main__calc-btn img {
    width: 1.5em;
    height: 1.5em;
}
.main__calc-thead-td-1 { width: 30% }
.main__calc-thead-td-2 { width: 25% }
.main__calc-thead-td-3 { width: 10% }
.main__calc-thead-td-4 { width: 15% }
.main__calc-thead-td-5 { width: 20% }
