.navIndent{ width: 2.6em }
.navPanel{
    height: 100%;
    position: fixed;
    left: 0;
    width: 2em;
    padding: 0 0.3em;
    background-color: var(--grey0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 900;
}
.navPanel__image{
    width: 100%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    &:active{
        transform: scale(0.96);
    }
}
.navPanel__top-items{ padding-top: 0.5em; }
.navPanel__bot-items{ padding-bottom: 0.5em; }
