.auth{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth__header{ color: var(--font-color) }
.auth__auth-form-container{
    border-radius: 1em;
    padding: 1em;
    background-color: var(--grey0);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}
.auth__form{
    display: flex;
    flex-direction: column;
}
.auth__form-button{
    border: 0;
    border-radius: 0.2em;
    cursor: pointer;
    box-shadow: 0 0 0.5em var(--containers-back);

    &:hover{ background-color: var(--success-color); };
    &:active{ transform: scale(0.98); }
}
input:focus {
    outline: none;
}
.auth__form-input{
    margin-bottom: 0.2em;
    border-radius: 0.2em;
    border: 0;
    height: 1.5em;
    width: 15em;
}
#authMsg{
    font-size: 90%;
    padding: 0;
    margin: 0 auto;
}
.auth__mmrzMe-container{
    margin: 0.2em 0 0.4em 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 85%;
}
.auth__mmrzMe-label{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: var(--font-color);
}
