.selectedComp_body{
    display: flex;
    flex-direction: column;
}
.selectedComp_company-data-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5em;
}
.selectedComp__comp-container{
    width: 50%;
    margin-right: 0.25em;
}
.selectedComp__rpr-container{
    width: 50%;
    margin-left: 0.25em;
}
.selectedComp__calc-container{}
.selectedComp__calc-data-body{}
.selectedComp__data-body {
    overflow-y: auto;
    height: 20vh;
}
.selectedComp__navBtn{
    color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
}
.selectedComp__navBtn-edit{
    &:active, &:hover{
        color: var(--main-color);
        box-shadow: 0 0 0.2em var(--main-color);
    }
}
.selectedComp__navBtn-delete{
    &:active, &:hover{
        color: var(--warning-color);
        box-shadow: 0 0 0.2em var(--warning-color);
    }
}
.selectedComp__save-btn{
    color: var(--success-color);
    box-shadow: 0 0 0.2em var(--success-color);
    margin-right: 0.25em;
}
.selectedComp__cancel-btn{
    color: var(--main-color);
    box-shadow: 0 0 0.2em var(--main-color);
    margin-left: 0.25em;
}
.selectedComp__delete-btn{
    color: var(--warning-color);
    box-shadow: 0 0 0.2em var(--warning-color);
    margin-right: 0.25em;
}
#compForm{
    height: 100%;
}
.selectedComp__table{ border-collapse: collapse; width: 100%; height: 100% }
.selectedComp__td{ border: 0.1em solid var(--grey2); padding: 0 0.25em; position: relative; height: 1.5em; overflow: hidden }
.selectedComp__td-static{ color: var(--main-color); text-align: start; width: 30% }
.selectedComp__td-dynamic{ width: 70%; color: var(--font-color) }
.selectedComp__input{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0 0.25em;
    border: 0;
    color: var(--font-color);
    background-color: var(--containers-back);
}
.selectedComp__select{
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    border: 0;
    background-color: var(--containers-back);
    color: var(--font-color);
    &:focus {
        outline: none;
    }
}
.selectedComp__rpr-table{
    border-collapse: collapse;
    width: 100%;
    height: 100%;
}
.selectedComp__rpr-td{
    border: 0.1em solid var(--grey2);
    height: 1.5em;
    padding: 0 0.25em;
    position: relative;
    color: var(--font-color);
}
.selectedComp__rpr-td-static{ color: var(--main-color); text-align: center }
.selectedComp__rpr-input{
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 0.5em);
    height: 100%;
    border: 0;
    padding: 0 0.25em;
    background-color: var(--grey-light);
    color: var(--font-color);
}
.selectedComp__rpr-new-td{ position: relative; width: 1.5em; height: 1.5em }
.selectedComp__icon-button{
    padding: 0.1em;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    z-index: 1;
    &:active { transform: scale(0.96) }
}
.selectedComp__icon{
    width: 80%;
    aspect-ratio: 1 / 1;
}
.selectedComp__save-button-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.selectedComp__rpr-edit-tools-container{
    position: absolute;
    background-color: black;
    z-index: 3;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0.5em;
    overflow: hidden;
    box-shadow: 0 0 0.3em black;
}
.selectedComp__rpr-edit-tools-btn{
    width: 2.3em;
    height: 2.5em;
    padding: 0.3em;
    background-color: var(--background);
    box-shadow: 0 0 0.3em var(--grey0);
    border: 0;
    cursor: pointer;
    &:active { transform: scale(0.96) }
}
.selectedComp__rpr-edit-tools-btnIco{
    width: 100%;
    height: 100%;
}
.selectedComp_reports-container{
    display: flex;
    flex-direction: row;
}
.selectedComp__calcs-container{ width: 50%; margin: 0.5em 0.25em 0 0 }
.selectedComp__history-container{ width: 50%; margin: 0.5em 0 0 0.25em }

.cmpny__calculation-table-container{ color: var(--font-color) }
.cmpny__calculation-table{ width: 100%; border-collapse: collapse; background-color: var(--grey2) }
.cmpny__calculation-table th {
    border: 0.1em solid var(--grey0);
    padding: 0.1em;
    word-wrap: break-word;
    white-space: normal;
}
.cmpny__calculation-table td {
    border: 0.1em solid var(--grey0);
    padding: 0.1em;
    word-wrap: break-word;
    white-space: normal;
    text-align: center;
}
.cmpny__income-color{ background-color: rgba(56, 255, 0, 0.05)
}
.cmpny__expenses-color{ background-color: rgba(255, 0, 0, 0.05)
}
.cmpny__table-income{ color: rgba(106, 255, 64, 0.7) }
.cmpny__table-expenses{ color: rgba(253, 91, 91, 0.7) }
.ctf-z{ width: 2vw; max-width: 2vw; text-align: center }
.ctf-a{ width: 20vw; max-width: 20vw; }
.ctf-b, .ctf-c, .ctf-d, .ctf-e, .ctf-f, .ctf-g, .ctf-h, .ctf-i, .ctf-j, .ctf-k, .ctf-l, .ctf-w{ width: 6.5vw; max-width: 6.5vw; }
.cmpny__field-editable{
    background-color: var(--containers-back);
    &:focus {
        outline: none;
    }
}
.selectedComp__calc-result-container { padding: 0.5em; background-color: var(--background) }

.selectedComp__calc-save-btn-container {
    position: relative;
    min-width: 5em;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.selectedComp__calc-save-btn {
    height: auto;
    color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
    &:active, &:hover{
        color: var(--success-color);
        box-shadow: 0 0 0.2em var(--success-color);
    }
}
.cmpny__table-addFiled-btn {
    background-color: rgba(110, 110, 110, 0);
    color: var(--success-color);
    border: 0;
    cursor: pointer;
    font-size: 110%;
    &:active { transform: scale(0.96) }
}
.cmpny__table-delFiled-btn {
    background-color: rgba(110, 110, 110, 0);
    color: var(--warning-color);
    border: 0;
    cursor: pointer;
    font-size: 110%;
    &:active { transform: scale(0.96) }
}
