.selectedComp__calc-data-body{ overflow-y: auto; }
.selectedComp__calculation-container-wrapper {
    padding: 0.5em 0.5em 0 0.5em;
}
.selectedComp__calculation-container {
    background-color: rgba(61, 127, 139, 0.3);
    border-radius: 0.25em;
    overflow: hidden;
    box-shadow: 0 0 0.1em black;
    display: flex;
    flex-direction: column;
}
.selectedComp__calculation-header {
    padding: 0.1em;

}
.selectedComp__calculation-footer {
    padding: 0.1em;
}
.selectedComp__calc-td-static {
    font-size: 90%;
    color: var(--grey2);
}
.selectedComp__calc-td-dynamic {
    color: var(--font-color);
}
.selectedComp__calc-td-btn-container {
    text-align: right;
}
.selectedComp__calc-btn-openPopup{
    border-radius: 0.25em;
    cursor: pointer;
    background-color: var(--grey2);
    border: 0;
    box-shadow: 0 0 0.1em white;
    color: white;
    height: 1.3em;
    width: 1.4em;
    text-align: center;
    align-items: center;
    padding: 0;
    &:active {
        transform: scale(0.96);
    }
}
.selectedComp__calc-btn-openPopup-unwrapIco{
    width: 100%;
    height: 100%;
}
.selectedComp__calc-popup-body {
    width: 90vw;
    height: 90vh;
    background-color: var(--containers-back);
}


.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.popup h2 {
    margin-top: 0;
}

.popup-item {
    margin-bottom: 15px;
}

.popup-item label {
    font-weight: bold;
}

.popup-item span {
    margin-left: 10px;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
}

.popup-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-buttons button:hover {
    opacity: 0.8;
}

#deleteBtn {
    background-color: #ff4c4c;
    color: #fff;
}

#paidBtn {
    background-color: #4caf50;
    color: #fff;
}

#unpaidBtn {
    background-color: #f44336;
    color: #fff;
}

#editBtn {
    background-color: #ffc107;
    color: #fff;
}

#generatePdfBtn {
    background-color: #2196f3;
    color: #fff;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}
.selectedComp__calc-popup-body-wrapper {
    width: 90vw;
    height: 90vh;
    background-color: var(--containers-back);
    box-shadow: 0 0 0.1em var(--grey0);
    position: relative;
    overflow-y: auto;
}
.calcPopup-toolsPanel {
    position: absolute;
    left: 0;
    top: 0;
    width: 10em;
    height: calc(100% - 1em);
    box-shadow: 0 0 0.2em black;
    transition: 800ms;
    padding: 0.5em;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calcPopup-toolsPanel.hidden {
    left: -11em;
    transition: 800ms;
}
.calcPopup-toolsPanel-reveal.hidden {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.7em;
    height: 3em;
    background-color: var(--grey0);
    border-radius: 0 1em 1em 0;
    box-shadow: 0 0 0.3em white;
    transition: 800ms
}
.calcPopup-toolsPanel-reveal {
    position: absolute;
    left: 11em;
    top: 0;
    width: 1.7em;
    height: 3em;
    background-color: var(--grey0);
    border-radius: 0 1em 1em 0;
    transition: 800ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.calcPopup-toolsPanel-reveal-span {
    color: white;
    font-size: 200%;
    transition: 800ms

}
.calcPopup-toolsPanel-reveal-span.hidden {
    transform: scaleX(-1);
    transition: 800ms
}
.calcPopup__toolsBtn {
    width: 100%;
    background-color: var(--grey1);
    box-shadow: 0 0 0.2em var(--font-color);
    color: var(--font-color);
    margin-bottom: 0.5em;
    border-radius: 0.5em;
    border: 0;
    cursor: pointer;
    padding: 0.25em 0;
    &:active {
        transform: scale(0.98);
    }
}
.calcPopup__toolsBtn-unusable {
    padding: 0.25em 0;
    width: 100%;
    background-color: var(--containers-back);
    box-shadow: 0 0 0.2em var(--background);
    color: var(--background);
    margin-bottom: 0.5em;
    border-radius: 0.5em;
    border: 0;
}
.calcPopup__table { border-collapse: collapse; width: 100%; text-align: center }
.calcPopup__td { border: 0.01em solid var(--grey0) }
.calcPopup__td-static { color: var(--grey2) }
.calcPopup__td-dynamic { color: var(--font-color) }
.calcPopup__td-dynamic a {
    color: var(--ref-color);
    &:visited, &:active {
        color: var(--ref-color);
    }
}

.calcPopup__toolsBtn-delete {
    color: var(--warning-color);
    box-shadow: 0 0 0.2em var(--warning-color);
}
.calcPopup__toolsBtn-paid {
    &:active, &:hover {
        color: var(--success-color);
        box-shadow: 0 0 0.2em var(--success-color);
    }
}
.calcPopup__toolsBtn-notPaid {
    &:active, &:hover {
        color: var(--warning-color);
        box-shadow: 0 0 0.2em var(--warning-color);
    }
}
.calcPopup__toolsBtn-edit {
    &:active, &:hover {
        color: var(--main-color);
        box-shadow: 0 0 0.2em var(--main-color);
    }
}
.calc-result-expenses-tax { color: var(--warning-color) }
.calc-result-income-tax { color: var(--ref-color) }
.calc-result-profit { color: var(--success-color) }
.calcPopup__result-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;
}
.calcPopup__result-btn-container {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-end;
}
.calcPopup__save-btn {
    color: var(--success-color);
    box-shadow: 0 0 0.2em var(--success-color);
    margin-right: 0.5em;
    padding: 0.25em 0.5em;
    display: none;
}
.calcPopup__cancel-btn {
   color: var(--main-color);
    box-shadow: 0 0 0.2em var(--main-color);
    padding: 0.25em 0.5em;
    display: none;
}
.calcPopup__toolsBtn-pdf {
    &:active, &:hover {
        color: var(--ref-color);
        box-shadow: 0 0 0.2em var(--ref-color);
    }
}
.calcPopup__toolsBtn-pdf-recreate {
    &:active, &:hover {
        color: var(--ref-color);
        box-shadow: 0 0 0.2em var(--ref-color);
    }
}

.pdfUpdateLoader {
    display: none;
    background:
            linear-gradient(90deg,#0001 33%, rgba(138, 212, 255, 0.5) 50%,#0001 66%)
            var(--containers-back);
    background-size:300% 100%;
    animation: l1 1s infinite linear;
}
@keyframes l1 {
    0% {background-position: right}
}
.calcPopup__toolsBtn-delete-unusable { display: none }
