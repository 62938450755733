
.admin__body{ display: flex; flex-direction: row; color: var(--font-color) }
.admin__users-container{ width: 20%;
    height: 100%;
    margin-right: 0.25em;
}
.admin__content-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 3.6em);
}
.admin__content-container{
    width: 80%;
    height: 100%;
    margin-left: 0.25em;
}
.admin__data-body-users-list{
    overflow-y: auto;
}
.admin__user-button{
    width: 100%;
    padding: 0.2em 0;
    margin-bottom: 0.05em;
    color: var(--font-color);
    background-color: rgba(80, 80, 80, 0);
    box-shadow: 0 0.05em 0.2em var(--grey1);
    border: 0;
    cursor: pointer;
    &:hover{
        box-shadow: inset 0 0 0.2em var(--grey1);
    };
    &:active{
        box-shadow: inset 0 0 0.2em var(--grey1);
        color: var(--main-color);
    }
}
.admin__data-body-user-info{
    display: flex;
    flex-direction: row;
}
.admin__user-table-container{ width: 50%; height: 100%; padding: 0.3em }
.admin__userInfo-table{ width: 100% }
.admin__userInfo-table td{ padding: 0.2em; border-bottom: 0.01em solid var(--grey0) }
.admin__userInfo-td-static{ color: var(--main-color) }
.admin__user-permissions-container{ display: flex; flex-direction: row; justify-content: space-around }
.admin__user-permissions-window{ width: 50%; height: 80%; border-radius: 0.2em }
.admin__permissions-selected{ margin-right: 0.25em; }
.admin__permissions-available{ margin-left: 0.25em; }
.admin__user-permissions-window-header{
    color: var(--font-color);
    height: 4%;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.1em 0.1em var(--grey2);
}
.admin__user-permissions-window-body{ height: 96%; overflow-y: auto }
.admin__newUser-form{ width: 50%; display: flex; flex-direction: column }
.admin__input-field{
    border-radius: 0.3em;
    border: 0;
    height: 1.5em;
    margin-bottom: 0.3em;
}
.admin__label-status{ color: var(--font-color); margin-bottom: 0.3em }
.admin__label-role-user{ color: var(--font-color); }
.admin__label-role-superuser{ color: var(--font-color); margin-bottom: 0.6em }
.admin__from-btn{
    border-radius: 0.5em;
    background-color: var(--grey1);
    border: 0;
    color: var(--font-color);
    padding: 0.3em 0;
    cursor: pointer;
    box-shadow: 0 0 0.2em var(--font-color);
    &:active{ color: var(--success-color); box-shadow: 0 0 0.2em var(--success-color); transform: scale(0.98) }
    &:hover{ color: var(--success-color); box-shadow: 0 0 0.2em var(--success-color) }
}
.admin__user-permission{
    padding: 0.4em 0.2em;
    margin-bottom: 0.05em;
    color: var(--font-color);
    background-color: rgba(0, 255, 234, 0.05);
    box-shadow: 0 0.05em 0.2em var(--grey1);
    font-size: 90%;
}
.admin__user-permission-dynamic{
    cursor: pointer;
    &:hover{ box-shadow: inset 0 0 0.2em var(--grey1) };
    &:active{ box-shadow: inset 0 0 0.2em var(--grey1) }
}
.admin__nav-button{
    color: var(--font-color);
    box-shadow: 0 0 0.1em var(--font-color);
}
.admin__nav-button-delete{
    margin-right: 0.25em;
    color: var(--warning-color);
    box-shadow: 0 0 0.1em var(--warning-color);

}
.admin__nav-button-cancel{
    margin-left: 0.25em;
    color: var(--main-color);
    box-shadow: 0 0 0.1em var(--main-color);

}
.admin__nav-button-save{
    margin-left: 0.25em;
    color: var(--success-color);
    box-shadow: 0 0 0.1em var(--success-color);

}
.admin__create-button{
    &:active, &:hover{
        box-shadow: 0 0 0.2em var(--success-color);
        color: var(--success-color);
    }
}
.admin__delete-button{
    &:active, &:hover{
        box-shadow: 0 0 0.2em var(--warning-color);
        color: var(--warning-color);
    }
}
.admin__edit-button{
    &:active, &:hover{
        box-shadow: 0 0 0.2em var(--main-color);
        color: var(--main-color);
    }
}
.admin__pdf-content-container {
    width: 100%;
}
.admin__pdf-body-container {
    padding: 0.5em;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}
.admin__pdf-container {
    width: 60em;
    height: 80em;
    background-color: #efefef;
}
.admin__pdf-body-settings {
    padding: 0.5em;
}
.admin__pdf-manager-settings-table {
    width: 50%;
    border-collapse: collapse;
}
.admin__pdf-manager-settings-table td {
    border: 0.01em solid var(--grey0);
}
.admin__pdfSettings-input {
    height: 1.2em;
    background-color: var(--containers-back);
    color: var(--font-color);
    border: 0;
    padding: 0 0.5em;
    width: calc(100% - 1.3em);
}