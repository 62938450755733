.historyContainer__data-history-body{
    overflow-y: auto;
}
.historyContainer__history-item-block{
    padding: 0 0.5em;
}
.historyContainer__history-item-date-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0;
}
.historyContainer__history-date{
    padding: 0.15em 0.5em;
    background-color: rgba(72, 61, 139, 0.6);
    border-radius: 0.5em;
    color: var(--font-color);
    box-shadow: 0 0 0.1em var(--background);
}
.historyContainer__history-item{
    display: flex;
    flex-direction: row;
    background-color: rgba(61, 127, 139, 0.3);
    margin-bottom: 0.5em;
    border-radius: 0.5em;
    box-shadow: 0 0 0.1em black;
}
.historyContainer__history-item-time-container{ padding: 0.25em; color: var(--grey2); display: flex; flex-direction: column; }
.historyContainer__history-item-context-container{ padding: 0.25em; color: var(--font-color);
    font-size: 90%; }
.history__arrow-symbol { color: var(--grey1); font-size: 100%; }
.history__changedFieldName{ color: var(--main-color) }

.history-ref, .main__actionHistory-ref{
    color: var(--ref-color);
    &:active {
        color: var(--ref-color-active);
    };
    &:visited {
        color: var(--ref-color);
    }
}
.history__paid { color: var(--success-color) }
.history__notPaid { color: var(--warning-color) }