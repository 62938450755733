.profile_body{
    color: var(--font-color);
    padding: 0.5em;
    width: 100%;
    height: 60vh;
}
.profile__content-container{
    width: 50%;
}
.profile__data-body-user-info{
    padding: 0.5em;
    display: flex;
    justify-content: center;
}
.profile__nav-button{
    color: var(--font-color);
    box-shadow: 0 0 0.2em var(--font-color);
    &:active, &:hover{
        box-shadow: 0 0 0.2em var(--main-color);
        color: var(--main-color);
    }
}
.profile__save-button{
    margin-right: 0.25em;
    color: var(--success-color);
    box-shadow: 0 0 0.2em var(--success-color);
}
.profile__cancel-button{
    margin-left: 0.25em;
    color: var(--main-color);
    box-shadow: 0 0 0.2em var(--main-color);
}
.profile__table{ width: 80%; height: 40% }
.profile__td{ border-bottom: 0.01em solid black; width: 70% }
.profile__td-static{ color: var(--main-color); width: 30% }
.profile__err-field{ text-align: center; color: var(--warning-color) }